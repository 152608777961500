/**
 * 
 <div class="field field-name-field-img-background field-type-image field-label-hidden field-wrapper"><img src="https://estacas.dev.m22.mx/sites/default/files/portada_lasestacas.jpg" width="1360" height="928" alt="Las estacas" title="Las estacas" data-pagespeed-url-hash="3836560477" onload="pagespeed.CriticalImages.checkImageForCriticality(this);"></div>
 <div class="field field-name-field-img-logo field-type-image field-label-hidden field-wrapper desktop-container"><img src="/sites/default/files/logo_deskt.svg" alt="Logo" title="Logo" data-pagespeed-url-hash="1875717505" onload="pagespeed.CriticalImages.checkImageForCriticality(this);"></div>
 */

 import React from "react";
import CompraTickets from "./tickets_compra";
import * as app from "../fetch/fetchApi";
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';


  
const BackgroundImage = (data) => {
  const isBrowser = typeof window !== "undefined"

    if (isBrowser) {
  var wid= window.innerWidth
    }

  return (
    
   /* <div className={'body field'}>
    <div class="portada-logo" style={{position: 'absolute', zIndex: 10, left: '50%', transform: 'translate(-50%, 0)',width:'100%'}}><img class="show-for-large-up" style={{marginLeft:'auto',marginRight:'auto',marginTop: '15vh',width:'45%',maxHeight: '42vh'}} src="https://lasestacas.com/sites/default/files/le_desktop_tripadvisor.svg"/><img class="show-for-medium-only" style={{marginLeft:'auto',marginRight:'auto',marginTop: '10vh',maxHeight:'100vh',width: '56%'}} src="https://lasestacas.com/sites/default/files/le_mobile_tripadvisor.svg"/><img class="show-for-small-only" style={{marginLeft:'auto',marginRight:'auto',marginTop: '20vh', width:'90%'}} src="https://lasestacas.com/sites/default/files/le_tablet_tripadvisor.svg"/></div>
    <div class="field field-name-field-img-background field-type-image field-label-hidden field-wrapper"><img src="https://estacas.dev.m22.mx/sites/default/files/portada_lasestacas.jpg" width="100%" height="100%" alt="Las estacas" title="Las estacas" className={'estacas'} data-pagespeed-url-hash="3836560477" onload="pagespeed.CriticalImages.checkImageForCriticality(this);" /> <img class="estacas" src="https://estacas.dev.m22.mx/sites/default/files/bottom_flowers.png"/></div>
    /*<div class="field field-name-field-img-logo field-type-image field-label-hidden field-wrapper desktop-container"><img src="/sites/default/files/logo_deskt.svg" alt="Logo" title="Logo" data-pagespeed-url-hash="1875717505" onload="pagespeed.CriticalImages.checkImageForCriticality(this);"/></div>
    <CompraTickets />
    
  </div>*/
<div>
<article id="node-1612" class="node node-page view-mode-full" style={{position: 'inherit'}}>
  <div class="body field">
    <div class="portada-logo" style={{position: 'absolute', zIndex: 10, left: '50%', transform: 'translate(-50%, 0)',width:'100%'}}>
      <LazyLoadImage loading="lazy" width="648" height="325"  class="show-for-large-up" style={{marginLeft:'auto',marginRight:'auto',marginTop: '15vh',maxHeight: '42vh'}} width='45%' src={app.url+"/sites/default/files/le_desktop_tripadvisor.svg"}/>
      <LazyLoadImage loading="lazy" class="show-for-medium-only" style={{marginLeft:'auto',marginRight:'auto',marginTop: '10vh',maxHeight:'100vh'}} width='56%' src={app.url+"/sites/default/files/le_mobile_tripadvisor.svg"}/>
      <LazyLoadImage loading="lazy" width="382" height="288"  class="show-for-small-only" style={{marginLeft:'auto',marginRight:'auto',marginTop: '20vh',}}  width='90%' src={app.url+"/sites/default/files/le_tablet_tripadvisor.svg"}/>
      <CompraTickets data={data} />
    </div>
  </div>
  <div class="field field-name-field-img-background field-type-image field-label-hidden field-wrapper inicio">
  {wid <450? <LazyLoadComponent>  <LazyLoadImage width="450" height="600" loading="lazy" src={app.url+"/sites/default/files/portada_lasestacas.jpg"}  style={{height: 'inherit',objectFit:'cover'}}  alt="Las estacas" title="Las estacas"/></LazyLoadComponent>:<LazyLoadComponent>  <LazyLoadImage width="1440" height="1098" loading="lazy" src={app.url+"/sites/default/files/portada_lasestacas.jpg"} style={{height: 'inherit',objectFit:'cover'}}  alt="Las estacas" title="Las estacas"/></LazyLoadComponent>}
  </div>
</article>
</div>
  );
};

export default BackgroundImage;