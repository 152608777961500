

/*const CardEvento = () => {
  return (
    <section class="block block-views block-views-evento-home-2019-block block-views-evento-home-2019-block">
        <h2 class="block-title">Eventos</h2>
        <div class="view view-evento-home-2019 view-id-evento_home_2019 view-display-id-block view-dom-id-960252b2b8056f6cd9a90be47e71dc02">
            <div class="view-content slick-initialized slick-slider">
                <button class="slick-prev slick-arrow" aria-label="Previous" type="button" style={{display: 'block'}}>Previous</button>
                <div class="slick-list draggable">
                    <CardEventoContenido />
                    
                </div>
                <button class="slick-next slick-arrow" aria-label="Next" type="button" style={{display: 'block'}}>Next</button>
            </div>

        </div>
    </section>

<section class="block block-views block-views-evento-home-2019-block block-views-evento-home-2019-block">
   <h2 class="block-title">Eventos</h2>
   <div class="view view-evento-home-2019 view-id-evento_home_2019 view-display-id-block view-dom-id-d1eb1bfdcbb295210c7992b81e1319cc">
      <div class="view-content slick-initialized slick-slider">
         <button class="slick-prev slick-arrow flecha" aria-label="Previous" type="button" style={{display: 'block'}}>Previous</button>
         <div class="slick-list draggable">
            <div class="slick-track scrollHorizontal" style={{opacity: 1, width: '14326px', transform: 'translate3d(-1102px, 0px, 0px)'}}>
               <div class="views-row views-row-6 views-row-even views-row-last slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true" style={{width: '1102px'}} tabindex="-1">
                  <div class="field-content eventImg"><img src="https://lasestacas.com/sites/default/files/styles/eventos_slider_home/public/le_2108_paginasitioweb_experiencias_1200x660px.jpg?itok=m-3Tlzyn" width="600" height="330" alt="" /></div>
                  <div class="event-container views-fieldset" data-module="views_fieldsets" style={{paddingTop: '298px'}}>
                     <h3 class="field-content title-3-b"><a href="/eventos/experiencia-de-yoga-en-paddle" tabindex="-1">Experiencia de yoga en paddle</a></h3>
                     <p class="field-content event-body paragraph-sb">¿Quién dijo que el yoga no es un reto? En Las Estacas podrás vivir la experiencia de conectarte con la naturaleza desde...</p>
                     <fieldset class="date-wrapper emphasis-v views-fieldset" data-module="views_fieldsets" style={{marginTop: '25px'}}>
                        <legend> <span class="fieldset-legend">Fecha</span></legend>
                        <div class="fieldset-wrapper">
                           <fieldset class="start-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Inicio</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb">01/09/2021 - 08:00</span></div>
                           </fieldset>
                           <fieldset class="end-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Fin</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb"><span class="date-display-single">31/10/2021 - 18:00</span></span></div>
                           </fieldset>
                        </div>
                     </fieldset>
                     <a href="/eventos/experiencia-de-yoga-en-paddle" class="boton-azul" tabindex="-1">Más info </a>
                  </div>
                </div>
               <div class="views-row views-row-1 views-row-odd views-row-first slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style={{width: '1102px'}} tabindex="0">
                  <div class="field-content eventImg"><img src="https://lasestacas.com/sites/default/files/styles/eventos_slider_home/public/le_2005_paginasitioweb_experiencia_romantica_1200x660px.jpg?itok=42f0ufTQ" width="600" height="330" alt=""/></div>
                  <div class="event-container views-fieldset" data-module="views_fieldsets" style={{paddingTop: '298px'}}>
                     <h3 class="field-content title-3-b"><a href="/eventos/experiencia-romantica" tabindex="0">Experiencia romántica</a></h3>
                     <p class="field-content event-body paragraph-sb">¡Que el paraíso de Las Estacas sea el primer lugar que visitas después de la cuarentena! Vuelve al parque natural y disf...</p>
                     <fieldset class="date-wrapper emphasis-v views-fieldset" data-module="views_fieldsets" style={{marginTop: '25px'}}>
                        <legend> <span class="fieldset-legend">Fecha</span></legend>
                        <div class="fieldset-wrapper">
                           <fieldset class="start-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Inicio</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb">01/09/2021 - 08:00</span></div>
                           </fieldset>
                           <fieldset class="end-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Fin</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb"><span class="date-display-single">31/10/2021 - 18:00</span></span></div>
                           </fieldset>
                        </div>
                     </fieldset>
                     <a href="/eventos/experiencia-romantica" class="boton-azul" tabindex="0">Más info </a>
                  </div>
               </div>
              {/* <div class="views-row views-row-2 views-row-even slick-slide" data-slick-index="1" aria-hidden="true" style={{width: '1102px'}} tabindex="-1">
                  <div class="field-content eventImg"><img src="https://lasestacas.com/sites/default/files/styles/eventos_slider_home/public/le_2005_paginasitioweb_experiencias_diversion_1200x660px.jpg?itok=gA0PfvDv" width="600" height="330" alt=""/></div>
                  <div class="event-container views-fieldset" data-module="views_fieldsets" style={{paddingTop: '298px'}}>
                     <h3 class="field-content title-3-b"><a href="/eventos/experiencia-de-diversion" tabindex="-1">Experiencia de diversión</a></h3>
                     <p class="field-content event-body paragraph-sb">¡Que el paraíso de Las Estacas sea el primer lugar que visitas después de la cuarentena! Vuelve al parque natural y disf...</p>
                     <fieldset class="date-wrapper emphasis-v views-fieldset" data-module="views_fieldsets" style={{marginTop: '25px'}}>
                        <legend> <span class="fieldset-legend">Fecha</span></legend>
                        <div class="fieldset-wrapper">
                           <fieldset class="start-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Inicio</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb">01/09/2021 - 08:00</span></div>
                           </fieldset>
                           <fieldset class="end-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Fin</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb"><span class="date-display-single">31/10/2021 - 18:00</span></span></div>
                           </fieldset>
                        </div>
                     </fieldset>
                     <a href="/eventos/experiencia-de-diversion" class="boton-azul" tabindex="-1">Más info </a>
                  </div>
               </div>
               <div class="views-row views-row-3 views-row-odd slick-slide" data-slick-index="2" aria-hidden="true" style={{width: '1102px'}} tabindex="-1">
                  <div class="field-content eventImg"><img src="https://lasestacas.com/sites/default/files/styles/eventos_slider_home/public/le_2005_paginasitioweb_experiencia_relajante_1200x660px.jpg?itok=imropehV" width="600" height="330" alt=""/></div>
                  <div class="event-container views-fieldset" data-module="views_fieldsets" style={{paddingTop: '298px'}}>
                     <h3 class="field-content title-3-b"><a href="/eventos/experiencia-relajante" tabindex="-1"> Experiencia relajante</a></h3>
                     <p class="field-content event-body paragraph-sb">¡Que el paraíso de Las Estacas sea el primer lugar que visitas después de la cuarentena! Vuelve al parque natural y disf...</p>
                     <fieldset class="date-wrapper emphasis-v views-fieldset" data-module="views_fieldsets" style={{marginTop: '25px'}}>
                        <legend> <span class="fieldset-legend">Fecha</span></legend>
                        <div class="fieldset-wrapper">
                           <fieldset class="start-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Inicio</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb">01/09/2021 - 08:00</span></div>
                           </fieldset>
                           <fieldset class="end-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Fin</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb"><span class="date-display-single">31/10/2021 - 18:00</span></span></div>
                           </fieldset>
                        </div>
                     </fieldset>
                     <a href="/eventos/experiencia-relajante" class="boton-azul" tabindex="-1">Más info </a>
                  </div>
               </div>
               <div class="views-row views-row-4 views-row-even slick-slide" data-slick-index="3" aria-hidden="true" style={{width: '1102px'}} tabindex="-1">
                  <div class="field-content eventImg"><img src="https://lasestacas.com/sites/default/files/styles/eventos_slider_home/public/le_2005_paginasitioweb_experiencias_buceo_1200x660px.jpg?itok=WR7a2YIq" width="600" height="330" alt=""/></div>
                  <div class="event-container views-fieldset" data-module="views_fieldsets" style={{paddingTop: '298px'}}>
                     <h3 class="field-content title-3-b"><a href="/eventos/experiencia-de-buceo" tabindex="-1">Experiencia de buceo</a></h3>
                     <p class="field-content event-body paragraph-sb">¡Que el paraíso de Las Estacas sea el primer lugar que visitas después de la cuarentena! Vuelve al parque natural y disf...</p>
                     <fieldset class="date-wrapper emphasis-v views-fieldset" data-module="views_fieldsets" style={{marginTop: '25px'}}>
                        <legend> <span class="fieldset-legend">Fecha</span></legend>
                        <div class="fieldset-wrapper">
                           <fieldset class="start-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Inicio</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb">01/09/2021 - 08:00</span></div>
                           </fieldset>
                           <fieldset class="end-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Fin</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb"><span class="date-display-single">31/10/2021 - 18:00</span></span></div>
                           </fieldset>
                        </div>
                     </fieldset>
                     <a href="/eventos/experiencia-de-buceo" class="boton-azul" tabindex="-1">Más info </a>
                  </div>
               </div>
               <div class="views-row views-row-5 views-row-odd slick-slide" data-slick-index="4" aria-hidden="true" style={{width: '1102px'}} tabindex="-1">
                  <div class="field-content eventImg"><img src="https://lasestacas.com/sites/default/files/styles/eventos_slider_home/public/le_2005_paginasitioweb_experiencias_1200x660px.jpg?itok=53xftl87" width="600" height="330" alt=""/></div>
                  <div class="event-container views-fieldset" data-module="views_fieldsets" style={{paddingTop: '298px'}}>
                     <h3 class="field-content title-3-b"><a href="/eventos/experiencia-de-aventura" tabindex="-1">Experiencia de aventura</a></h3>
                     <p class="field-content event-body paragraph-sb">¡Que el paraíso de Las Estacas sea el primer lugar que visitas después de la cuarentena! Vuelve al parque natural y disf...</p>
                     <fieldset class="date-wrapper emphasis-v views-fieldset" data-module="views_fieldsets" style={{marginTop: '25px'}}>
                        <legend> <span class="fieldset-legend">Fecha</span></legend>
                        <div class="fieldset-wrapper">
                           <fieldset class="start-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Inicio</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb">01/09/2021 - 08:00</span></div>
                           </fieldset>
                           <fieldset class="end-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Fin</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb"><span class="date-display-single">31/10/2021 - 18:00</span></span></div>
                           </fieldset>
                        </div>
                     </fieldset>
                     <a href="/eventos/experiencia-de-aventura" class="boton-azul" tabindex="-1">Más info </a>
                  </div>
               </div>
               <div class="views-row views-row-6 views-row-even views-row-last slick-slide" data-slick-index="5" aria-hidden="true" style={{width: '1102px'}} tabindex="-1">
                  <div class="field-content eventImg"><img src="https://lasestacas.com/sites/default/files/styles/eventos_slider_home/public/le_2108_paginasitioweb_experiencias_1200x660px.jpg?itok=m-3Tlzyn" width="600" height="330" alt=""/></div>
                  <div class="event-container views-fieldset" data-module="views_fieldsets" style={{paddingTop: '298px'}}>
                     <h3 class="field-content title-3-b"><a href="/eventos/experiencia-de-yoga-en-paddle" tabindex="-1">Experiencia de yoga en paddle</a></h3>
                     <p class="field-content event-body paragraph-sb">¿Quién dijo que el yoga no es un reto? En Las Estacas podrás vivir la experiencia de conectarte con la naturaleza desde...</p>
                     <fieldset class="date-wrapper emphasis-v views-fieldset" data-module="views_fieldsets" style={{marginTop: '25px'}}>
                        <legend> <span class="fieldset-legend">Fecha</span></legend>
                        <div class="fieldset-wrapper">
                           <fieldset class="start-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Inicio</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb">01/09/2021 - 08:00</span></div>
                           </fieldset>
                           <fieldset class="end-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Fin</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb"><span class="date-display-single">31/10/2021 - 18:00</span></span></div>
                           </fieldset>
                        </div>
                     </fieldset>
                     <a href="/eventos/experiencia-de-yoga-en-paddle" class="boton-azul" tabindex="-1">Más info </a>
                  </div>
               </div>
               <div class="views-row views-row-1 views-row-odd views-row-first slick-slide slick-cloned" data-slick-index="6" aria-hidden="true" style={{width: '1102px'}} tabindex="-1">
                  <div class="field-content eventImg"><img src="https://lasestacas.com/sites/default/files/styles/eventos_slider_home/public/le_2005_paginasitioweb_experiencia_romantica_1200x660px.jpg?itok=42f0ufTQ" width="600" height="330" alt=""/></div>
                  <div class="event-container views-fieldset" data-module="views_fieldsets" style={{paddingTop: '298px'}}>
                     <h3 class="field-content title-3-b"><a href="/eventos/experiencia-romantica" tabindex="-1">Experiencia romántica</a></h3>
                     <p class="field-content event-body paragraph-sb">¡Que el paraíso de Las Estacas sea el primer lugar que visitas después de la cuarentena! Vuelve al parque natural y disf...</p>
                     <fieldset class="date-wrapper emphasis-v views-fieldset" data-module="views_fieldsets" style={{marginTop: '25px'}}>
                        <legend> <span class="fieldset-legend">Fecha</span></legend>
                        <div class="fieldset-wrapper">
                           <fieldset class="start-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Inicio</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb">01/09/2021 - 08:00</span></div>
                           </fieldset>
                           <fieldset class="end-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Fin</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb"><span class="date-display-single">31/10/2021 - 18:00</span></span></div>
                           </fieldset>
                        </div>
                     </fieldset>
                     <a href="/eventos/experiencia-romantica" class="boton-azul" tabindex="-1">Más info </a>
                  </div>
               </div>
               <div class="views-row views-row-2 views-row-even slick-slide slick-cloned" data-slick-index="7" aria-hidden="true" style={{width: '1102px'}} tabindex="-1">
                  <div class="field-content eventImg"><img src="https://lasestacas.com/sites/default/files/styles/eventos_slider_home/public/le_2005_paginasitioweb_experiencias_diversion_1200x660px.jpg?itok=gA0PfvDv" width="600" height="330" alt=""/></div>
                  <div class="event-container views-fieldset" data-module="views_fieldsets" style={{paddingTop: '298px'}}>
                     <h3 class="field-content title-3-b"><a href="/eventos/experiencia-de-diversion" tabindex="-1">Experiencia de diversión</a></h3>
                     <p class="field-content event-body paragraph-sb">¡Que el paraíso de Las Estacas sea el primer lugar que visitas después de la cuarentena! Vuelve al parque natural y disf...</p>
                     <fieldset class="date-wrapper emphasis-v views-fieldset" data-module="views_fieldsets" style={{marginTop: '25px'}}>
                        <legend> <span class="fieldset-legend">Fecha</span></legend>
                        <div class="fieldset-wrapper">
                           <fieldset class="start-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Inicio</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb">01/09/2021 - 08:00</span></div>
                           </fieldset>
                           <fieldset class="end-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Fin</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb"><span class="date-display-single">31/10/2021 - 18:00</span></span></div>
                           </fieldset>
                        </div>
                     </fieldset>
                     <a href="/eventos/experiencia-de-diversion" class="boton-azul" tabindex="-1">Más info </a>
                  </div>
               </div>
               <div class="views-row views-row-3 views-row-odd slick-slide slick-cloned" data-slick-index="8" aria-hidden="true" style={{width: '1102px'}} tabindex="-1">
                  <div class="field-content eventImg"><img src="https://lasestacas.com/sites/default/files/styles/eventos_slider_home/public/le_2005_paginasitioweb_experiencia_relajante_1200x660px.jpg?itok=imropehV" width="600" height="330" alt=""/></div>
                  <div class="event-container views-fieldset" data-module="views_fieldsets" style={{paddingTop: '298px'}}>
                     <h3 class="field-content title-3-b"><a href="/eventos/experiencia-relajante" tabindex="-1"> Experiencia relajante</a></h3>
                     <p class="field-content event-body paragraph-sb">¡Que el paraíso de Las Estacas sea el primer lugar que visitas después de la cuarentena! Vuelve al parque natural y disf...</p>
                     <fieldset class="date-wrapper emphasis-v views-fieldset" data-module="views_fieldsets" style={{marginTop: '25px'}}>
                        <legend> <span class="fieldset-legend">Fecha</span></legend>
                        <div class="fieldset-wrapper">
                           <fieldset class="start-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Inicio</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb">01/09/2021 - 08:00</span></div>
                           </fieldset>
                           <fieldset class="end-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Fin</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb"><span class="date-display-single">31/10/2021 - 18:00</span></span></div>
                           </fieldset>
                        </div>
                     </fieldset>
                     <a href="/eventos/experiencia-relajante" class="boton-azul" tabindex="-1">Más info </a>
                  </div>
               </div>
               <div class="views-row views-row-4 views-row-even slick-slide slick-cloned" data-slick-index="9" aria-hidden="true" style={{width: '1102px'}} tabindex="-1">
                  <div class="field-content eventImg"><img src="https://lasestacas.com/sites/default/files/styles/eventos_slider_home/public/le_2005_paginasitioweb_experiencias_buceo_1200x660px.jpg?itok=WR7a2YIq" width="600" height="330" alt=""/></div>
                  <div class="event-container views-fieldset" data-module="views_fieldsets" style={{paddingTop: '298px'}}>
                     <h3 class="field-content title-3-b"><a href="/eventos/experiencia-de-buceo" tabindex="-1">Experiencia de buceo</a></h3>
                     <p class="field-content event-body paragraph-sb">¡Que el paraíso de Las Estacas sea el primer lugar que visitas después de la cuarentena! Vuelve al parque natural y disf...</p>
                     <fieldset class="date-wrapper emphasis-v views-fieldset" data-module="views_fieldsets" style={{marginTop: '25px'}}>
                        <legend> <span class="fieldset-legend">Fecha</span></legend>
                        <div class="fieldset-wrapper">
                           <fieldset class="start-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Inicio</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb">01/09/2021 - 08:00</span></div>
                           </fieldset>
                           <fieldset class="end-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Fin</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb"><span class="date-display-single">31/10/2021 - 18:00</span></span></div>
                           </fieldset>
                        </div>
                     </fieldset>
                     <a href="/eventos/experiencia-de-buceo" class="boton-azul" tabindex="-1">Más info </a>
                  </div>
               </div>
               <div class="views-row views-row-5 views-row-odd slick-slide slick-cloned" data-slick-index="10" aria-hidden="true" style={{width: '1102px'}} tabindex="-1">
                  <div class="field-content eventImg"><img src="https://lasestacas.com/sites/default/files/styles/eventos_slider_home/public/le_2005_paginasitioweb_experiencias_1200x660px.jpg?itok=53xftl87" width="600" height="330" alt=""/></div>
                  <div class="event-container views-fieldset" data-module="views_fieldsets" style={{paddingTop: '298px'}}>
                     <h3 class="field-content title-3-b"><a href="/eventos/experiencia-de-aventura" tabindex="-1">Experiencia de aventura</a></h3>
                     <p class="field-content event-body paragraph-sb">¡Que el paraíso de Las Estacas sea el primer lugar que visitas después de la cuarentena! Vuelve al parque natural y disf...</p>
                     <fieldset class="date-wrapper emphasis-v views-fieldset" data-module="views_fieldsets" style={{marginTop: '25px'}}>
                        <legend> <span class="fieldset-legend">Fecha</span></legend>
                        <div class="fieldset-wrapper">
                           <fieldset class="start-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Inicio</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb">01/09/2021 - 08:00</span></div>
                           </fieldset>
                           <fieldset class="end-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Fin</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb"><span class="date-display-single">31/10/2021 - 18:00</span></span></div>
                           </fieldset>
                        </div>
                     </fieldset>
                     <a href="/eventos/experiencia-de-aventura" class="boton-azul" tabindex="-1">Más info </a>
                  </div>
               </div>
               <div class="views-row views-row-6 views-row-even views-row-last slick-slide slick-cloned" data-slick-index="11" aria-hidden="true" style={{width: '1102px'}} tabindex="-1">
                  <div class="field-content eventImg"><img src="https://lasestacas.com/sites/default/files/styles/eventos_slider_home/public/le_2108_paginasitioweb_experiencias_1200x660px.jpg?itok=m-3Tlzyn" width="600" height="330" alt=""/></div>
                  <div class="event-container views-fieldset" data-module="views_fieldsets" style={{paddingTop: '298px'}}>
                     <h3 class="field-content title-3-b"><a href="/eventos/experiencia-de-yoga-en-paddle" tabindex="-1">Experiencia de yoga en paddle</a></h3>
                     <p class="field-content event-body paragraph-sb">¿Quién dijo que el yoga no es un reto? En Las Estacas podrás vivir la experiencia de conectarte con la naturaleza desde...</p>
                     <fieldset class="date-wrapper emphasis-v views-fieldset" data-module="views_fieldsets" style={{marginTop: '25px'}}>
                        <legend> <span class="fieldset-legend">Fecha</span></legend>
                        <div class="fieldset-wrapper">
                           <fieldset class="start-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Inicio</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb">01/09/2021 - 08:00</span></div>
                           </fieldset>
                           <fieldset class="end-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Fin</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb"><span class="date-display-single">31/10/2021 - 18:00</span></span></div>
                           </fieldset>
                        </div>
                     </fieldset>
                     <a href="/eventos/experiencia-de-yoga-en-paddle" class="boton-azul" tabindex="-1">Más info </a>
                  </div>
               </div>
            </div>
         </div>
         <button class="slick-next slick-arrow flecha" aria-label="Next" type="button" style={{display: 'block'}}>Next</button>
      </div>
      <div class="view-footer"> <a href="/eventos" class="more-content btn-tex-mob-sb">Ver todos los eventos</a></div>
   </div>
  </section>

    );
};*/

//import "~slick-carousel/slick/slick.css"; <img src={"https://s3.amazonaws.com/static.neostack.com/img/react-slick/abstract01.jpg"} />
//import "~slick-carousel/slick/slick-theme.css";

//field-content event-body paragraph-sb clases p
//import { baseUrl } from "./config";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import React, { Component } from "react";
import Slider from "react-slick";
import * as app from "../fetch/fetchApi";
import { LazyLoadImage } from 'react-lazy-load-image-component';



export default class CardEvento extends Component {
  render() {
     var datos = this.props.data;
     
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      
    };
    return (
      <div>
         {console.log(datos)}
        <Slider {...settings}>
           { datos.map((data) => (
          <div style={{marginLeft:'auto',marginRight:'auto'}}>
          <div class="views-row views-row-6 views-row-even views-row-last slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true" tabindex="-1">
                  <div class="field-content eventImg"><LazyLoadImage src={data.event_image_url} width="600" height="330" alt="" /></div>
                  <div class="event-container views-fieldset heightCardEventos" data-module="views_fieldsets" style={{padding:'18px 18px'}}>
                     <div className="heightTexto">
                     <h3 class="field-content title-3-b">{  <div dangerouslySetInnerHTML={{__html: data.event_title}} /> }</h3>
                     </div>
                     <div>
                     <p class="field-content event-body paragraph-sb">{data.body_text}</p>
                     </div>
                     <fieldset class="date-wrapper emphasis-v views-fieldset d-lg-block d-md-none d-none" data-module="views_fieldsets" style={{marginTop: '25px'}}>
                        <legend> <span class="fieldset-legend">Fecha</span></legend>
                        <div class="fieldset-wrapper d-grid d-lg-block">
                           <fieldset class="start-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Inicio</span></legend>
                              <div class="fieldset-wrapper"> {  <div dangerouslySetInnerHTML={{__html: data.fecha_evento_inicio}} /> }</div>
                           </fieldset>
                           <fieldset class="end-date views-fieldset" data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Fin</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb">{  <div dangerouslySetInnerHTML={{__html: data.fecha_evento_fin}} /> }</span></div>
                           </fieldset>
                        </div>
                     </fieldset>

                     
                     {/*<div>

   <legend> <span class="fieldset-legend fechaCardEventos">Fecha</span></legend>
   <div class="fieldset-wrapper d-flex">
      

         <div class="fieldset-wrapper ">
             <span class="field-content event-date paragraph-sb">01/01/2022 - 08:00</span>
             </div>
             
             
                <div class="fieldset-wrapper">
                    <span class="field-content event-date paragraph-sb">
                       <span class="date-display-single">28/02/2022 - 18:00</span>
                </span>
                </div>
                
                </div>

                     </div>*/}


                     {/*  <div  style={{display: 'inherit'}} dangerouslySetInnerHTML={{__html: data.more_info_target}} />*/ }
                     <div style={{display: 'inherit'}}>
                        <div className="d-lg-none d-md-flex d-none">
                        <fieldset class="start-date views-fieldset" style={{border:'0px'}} data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Inicio</span></legend>
                              <div class="fieldset-wrapper"> {  <div dangerouslySetInnerHTML={{__html: data.fecha_evento_inicio}} /> }</div>
                           </fieldset>
                           <fieldset class="end-date views-fieldset" style={{border:'0px'}} data-module="views_fieldsets">
                              <legend> <span class="fieldset-legend">Fin</span></legend>
                              <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb">{  <div dangerouslySetInnerHTML={{__html: data.fecha_evento_fin}} /> }</span></div>
                           </fieldset>
           </div>


          
                     

                     <a href={app.url+data.url} style={{width:'124px',height:'48px'}}  class="boton-azul botonCardEventos me-3 mb-1 text-center" tabindex="0">Más info </a>
                     </div>
                  </div>
                </div>
          </div>
           ))
         }

          {/*<div>
          <img src={"https://s3.amazonaws.com/static.neostack.com/img/react-slick/abstract03.jpg"}style={{marginLeft:'auto',marginRight:'auto'}} />
          </div>
          <div>
          <img src={"https://s3.amazonaws.com/static.neostack.com/img/react-slick/abstract04.jpg"}style={{marginLeft:'auto',marginRight:'auto'}} />
          </div>
          <div>
          <img src={"https://s3.amazonaws.com/static.neostack.com/img/react-slick/abstract01.jpg"}style={{marginLeft:'auto',marginRight:'auto'}} />
          </div>
          <div>
          <img src={"https://s3.amazonaws.com/static.neostack.com/img/react-slick/abstract02.jpg"}style={{marginLeft:'auto',marginRight:'auto'}} />
          </div>*/}
        </Slider>
      </div>
    );
  }
}

//export default CardEvento;