import React ,{useEffect,useState,}from "react";
import Banner from "../components/banner";
import BackgroundImage from "../components/background_image";

import PreFooter from "../components/prefooter";
import NewsLetter from "../components/newsletter";
import MenuFooter from "../components/menu_footer";
import Politicas from "../components/politicas";
import Redes from "../components/redes";
import Copyright from "../components/copyright";
import HeaderCard from "../components/header_card";
import ContentCard from "../components/content_card";
import CardEvento from "../components/card_eventos";
import ContentInstagram from "../components/content_instragram";

import CarouselCardAtracciones from "../components/carousel_card_atracciones";
import BannerMobile from "../components/banner_mobile";
import ModalComplementa from "../components/modal_complementa";
import { wrapper } from 'axios-cookiejar-support';
import * as app from "../fetch/fetchApi";
import { Helmet } from "react-helmet"
import $ from 'jquery'
import { LazyLoadComponent } from "react-lazy-load-image-component";

import Mapa from "../components/mapa";
import CompraTicketsMobile from "../components/tickets_compra_mobile";

const axios = require('axios');


const jar = app.jar;
const client = wrapper(axios.create({ jar }));
/*export class Header extends React.Component {


    render(){
        <div>
        <Banner />
        <BackgroundImage />
        
        </div>
    }
}*/

/*const Header = () => {
    return (
        <div>
        <PreFooter />
        <footer className={'l-footer panel'} role={'contentinfo'}>
            <div className={'footer'}>
            <NewsLetter />
            <MenuFooter />
            <Politicas />
            <Redes />
            </div>
            <Copyright />
        </footer>
        </div>
    );
  };
  */

  /*const Header = () => {
    return (
        
        <main className={'row l-main header-visible'}>
            <div className={' main columns'}>
        <section className={'block block-views block-views-cards-home-atracciones-block block-views-cards-home-atracciones-block'}>
            <div className={'view view-cards-home-atracciones view-id-cards_home_atracciones view-display-id-block view-dom-id-a1d681395865d9cc26a3b0abd78889c2'}>
                <HeaderCard />
                <ContentCard />
                <div class="view-footer"> <a href="/atracciones" class="more-content btn-tex-mob-sb">Ver todos las atracciones</a></div>
            </div>
        </section> 
        </div>
        </main>
       
    );
  };*/
/*
  export class Header extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {
          data: undefined,
          dataInstagram:undefined,
          dataEvento:undefined,
          dataMenu:undefined,
          dataMenuFooter: undefined,
          dataTickets:undefined
        };
    }


    componentDidMount(){

      $( "#map-container" ).click(function() {
        alert( "Handler for .click() called." );
      });

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
           }
        };
          fetch("https://estacas.dev.m22.mx/api/v1/views/cards_home_atracciones?display_id=services_1&format_output=0  ",requestOptions)
        .then((response) => {
          console.log(response.status);
          if(response.status===200) {
            //console.log(response.json())
            var data= response.json();
            console.log(data)
            
            return data;
          } else {
           return false;
          }
        })
        .then((recurso) => {
          console.log(recurso)
          if (recurso!==undefined) {
            this.setState({
                data: recurso,
              }); 
          }
          
    })

    const requestOptions2 = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
       }
    };
      fetch("https://estacas.dev.m22.mx/api/v1/views/instagram_slider?display_id=services_1&format_output=0",requestOptions2)
    .then((response) => {
      console.log(response.status);
      if(response.status===200) {
        //console.log(response.json())
        var data= response.json();
        console.log(data)
        
        return data;
      } else {
       return false;
      }
    })
    .then((recurso) => {
      console.log(recurso)
      if (recurso!==undefined) {
        this.setState({
            dataInstagram: recurso,
          }); 
      }
      
})


const requestOptions3 = {
  method: 'GET',
  headers: { 'Content-Type': 'application/json',
 }
};
fetch("https://estacas.dev.m22.mx/api/v1/views/evento_home_2019?display_id=services_1&format_output=0",requestOptions3)
.then((response) => {
console.log(response.status);
if(response.status===200) {
  //console.log(response.json())
  var data= response.json();
  console.log(data)
  
  return data;
} else {
 return false;
}
})
.then((recurso) => {
console.log(recurso)
if (recurso!==undefined) {
  this.setState({
      dataEvento: recurso,
    }); 
}

})


/*const requestOptions4 = {
  method: 'GET',
  headers: { 'Content-Type': 'application/json',
 }
};
fetch("https://estacas.dev.m22.mx/js-api/m22_item_menu/main-menu",requestOptions4)
.then((response) => {
console.log(response.status);
if(response.status===200) {
  //console.log(response.json())
  var data= response.json();
  console.log(data)
  
  return data;
} else {
 return false;
}
})
.then((recurso) => {
console.log(recurso)
if (recurso!==undefined) {
  this.setState({
      dataMenu: recurso,
    }); 
}

})

const requestOptions5 = {
  method: 'POST',
  headers: { 'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': 'http://localhost:8000/',
 }
};
fetch("https://silvio.estacas.dev.m22.mx/node-api/m22_item_menu/main-menu")
.then((response) => {
console.log(response.status);
if(response.status===200) {
  //console.log(response.json())
  var data= response.json();
  console.log(data)
  
  return data;
} else {
 return false;
}
})
.then((recurso) => {
console.log(recurso)
if (recurso!==undefined) {
  this.setState({
      dataMenu: recurso,
    }); 
}

})


fetch("https://silvio.estacas.dev.m22.mx/node-api/m22_item_menu/menu-foooter-menu-2019")
.then((response) => {
console.log(response.status);
if(response.status===200) {
  //console.log(response.json())
  var data= response.json();
  console.log(data)
  
  return data;
} else {
 return false;
}
})
.then((recurso) => {
console.log(recurso)
if (recurso!==undefined) {
  this.setState({
      dataMenuFooter: recurso,
    }); 
}

})



  fetch('https://silvio.estacas.dev.m22.mx/node-api/views/prueba_tickets_componente?display_id=services_1&format_output=0')
  .then((response) => {
console.log(response.status);
if(response.status===200) {
  //console.log(response.json())
  var data= response.json();
  console.log(data)
  
  return data;
} else {
 return false;
}
})
.then((recurso) => {
console.log(recurso)
if (recurso!==undefined) {
  this.setState({
      dataTickets: recurso,
    }); 
}

})

}
      render(){
          let datos = this.state.data;
          let datosInstagram = this.state.dataInstagram;
          let datosEventos = this.state.dataEvento
          let datosMenu = this.state.dataMenu
          let datosMenuFooter = this.state.dataMenuFooter
          let datosTickets = this.state.dataTickets
          if (datos===undefined||datosInstagram===undefined||datosEventos===undefined||datosMenu===undefined||datosMenuFooter===undefined
            ||datosTickets===undefined) {
             return <h1>Loading...</h1>;
          }else{
           /* return(
              <div>
              
                <Banner />
                <ImagenSitioInteres />
                <SitioInteres />
                
                <CarouselCardComplementa />
                <div>
        <footer className={'l-footer panel'} role={'contentinfo'}>
            <div className={'footer'}>
            <NewsLetter />
            <MenuFooter />
            <Politicas />
            <Redes />
            </div>
            <Copyright />
        </footer>
        </div>
              </div>
            )
    return (
        <div>
       
<Banner data={datosMenu}/>
<BackgroundImage data={datosTickets} />
        
        <main className={'l-main header-visible'} role="main">
            <div className={' main'}>
            
            <div style={{padding: '0 10%'}}>  
        <section className={'block block-views block-views-cards-home-atracciones-block block-views-cards-home-atracciones-block'}>
        <CompraTicketsMobile data={datosTickets} /> 
        
            <div className={'view view-cards-home-atracciones view-id-cards_home_atracciones view-display-id-block view-dom-id-a1d681395865d9cc26a3b0abd78889c2'}>
                <HeaderCard />
                <ContentCard data={datos}/>
                
            </div>
        </section> 
        <Mapa />
       <ContentInstagram data={datosInstagram}/>
        <section class="block block-views block-views-evento-home-2019-block block-views-evento-home-2019-block">
   <h2 class="block-title">Eventos</h2>
   <div class="view view-evento-home-2019 view-id-evento_home_2019 view-display-id-block view-dom-id-d1eb1bfdcbb295210c7992b81e1319cc">
      <div class="view-content slick-initialized slick-slider">
        
            
        <div style={{margin: '0 auto',
          color: '#333',
          display: 'block'}}>
        <CardEvento  data={datosEventos}/>
        </div>
         
      </div>
      <div class="view-footer"> <a href="/eventos" class="more-content btn-tex-mob-sb">Ver todos los eventos</a></div>
   </div>
  </section>
  </div>
        </div>
        
        </main>
        <PreFooter />
        <footer className={'l-footer panel'} role={'contentinfo'}>
            <div className={'footer'}>
            <NewsLetter />
            <MenuFooter data={datosMenuFooter}/>
            <Politicas />
            <Redes />
            </div>
            <Copyright />
        </footer>
        <span id={'mostrarTooltip'} data-selector="tooltip-kxqp5vmd1" id="tooltip-kxqp5vmd1" class="tooltip tip-top custom-open"  role="tooltip" style={{visibility: 'visible', width: 'auto', inset: '610.594px auto auto 739.969px', }}>En caso de no acreditar la estatura, tendrás que pagar la diferencia en taquilla.<span class="nub"></span></span>
        </div>
    );
  };
}
  }
*/

const Header = () => {
  const isBrowser = typeof window !== "undefined"
let width;
/**
  data: undefined,
          dataInstagram:undefined,
          dataEvento:undefined,
          dataMenu:undefined,
          dataMenuFooter: undefined,
          dataTickets:undefined
 */
  const [datos, setData] = useState([])
  const [datosInstagram, setDataInstagram] = useState([])
  const [datosEventos, setDataEvento] = useState([])
  const [datosMenu, setDataMenu] = useState({})
  const [datosMenuFooter, setDataMenuFooter] = useState({})
  const [datosTickets, setDataTickets] = useState([])
  
console.log(datos)
useEffect(() => {
  // get data from GitHub api
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json',
   }
};
  fetch(app.url+`/node-api/views/prueba_tickets_componente?display_id=services_1&format_output=0`,{
    method: 'GET',
      headers: { 'Content-Type': 'application/json',
     }
  })
    .then(response =>{
      console.log(response.status);
      if(response.status===200) {
        //console.log(response.json())
        var data= response.json();
        console.log(data)
        
        return data;
      } else {
       return false;
      }
    }) // parse JSON from request
    .then(resultData => {
      console.log(resultData)
      setDataTickets(resultData)
    })
     // set data for the number of stars
}, [])

useEffect(() => {
  
  // get data from GitHub api
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json',
   }
};
  fetch(app.url+`/api/v1/views/prueba_evento_home_2019?display_id=services_1&format_output=0`,{
    method: 'GET',
      headers: { 'Content-Type': 'application/json',
     }
  })
    .then(response => response.json()) // parse JSON from request
    .then(resultData => {
      console.log(resultData)
      setDataEvento(resultData)
    })
     // set data for the number of stars
}, [])

  useEffect(() => {
    // get data from GitHub api
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json',
     }
  };
    fetch(app.url+`/api/v1/views/cards_atracciones?display_id=services_1&format_output=0`,{
      method: 'GET',
      headers: { 'Content-Type': 'application/json',
     }
    })
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setData(resultData)
      })
       // set data for the number of stars
  }, [])

  useEffect(() => {
    // get data from GitHub api
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json',
     }
  };
    fetch(app.url+`/api/v1/views/prueba_instagram_slider?display_id=services_1&format_output=0`,{
      method: 'GET',
      
      headers: { 'Content-Type': 'application/json',
     }
    })
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataInstagram(resultData)
      })
       // set data for the number of stars
  }, [])

  useEffect(() => {
    // get data from GitHub api
    

    client.get(app.url+'/node-api/m22_item_menu/menu-foooter-menu-2019', {
      // method: 'POST', // or 'PUT'
      // credentials: 'include',
       withCredentials: true,
       //jar:CookieJar,
       //data: datos, // data can be `string` or {object}!
       headers: {
         // Overwrite Axios's automatically set Content-Type
         crossDomain:true,
         'Content-Type': 'application/json'
       },
     })
     .then(function (response) {
       console.log(response.data);
       
       console.log(document.cookie);
       //console.log(response.json())
       setDataMenuFooter(response.data)
       console.log(datosMenu)
     })
     .catch(function (error) {
       
     });
    /*fetch(`https://silvio.estacas.dev.m22.mx/node-api/m22_item_menu/menu-foooter-menu-2019`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataMenuFooter(resultData)
      })*/
       // set data for the number of stars
  }, [])

  useEffect(() => {
    // get data from GitHub api

    client.get(app.url+'/node-api/m22_item_menu/main-menu', {
      // method: 'POST', // or 'PUT'
      // credentials: 'include',
       withCredentials: true,
       //jar:CookieJar,
       //data: datos, // data can be `string` or {object}!
       headers: {
         // Overwrite Axios's automatically set Content-Type
         crossDomain:true,
         'Content-Type': 'application/json'
       },
     })
     .then(function (response) {
       console.log(response.data);
       
       console.log(document.cookie);
       //console.log(response.json())
       setDataMenu(response.data)
       console.log(datosMenu)
     })
     .catch(function (error) {
       
     });

/*
    fetch(`https://silvio.estacas.dev.m22.mx/node-api/m22_item_menu/main-menu`,{
      method:'get',
      credentials: 'include',
    })
      .then(response => {for(let entry of response.headers.entries()) {
        console.log('header', entry);
      }
        console.log(response.headers.get('set-cookie'));
        return response.json()}) // parse JSON from request
      .then(resultData => {
        
        console.log(document.cookie);
        console.log(resultData)
        setDataMenu(resultData)
      })*/
       // set data for the number of stars
  }, [])
  if (isBrowser) {
  width = window.innerWidth 
  console.log(width)
  //document.cookie="user=silvio"


  $(document).ready(
  
    function()
    {
      setTimeout(() => {
        //$('.lazy').lazy();
      }, 5000);

});
  }

  const loading = <p>Loading</p>
   
  return (
    <div>
     

 { width <1100?<Banner data={datosMenu}/>:<BannerMobile data={datosMenu} />
}
{datosTickets.length!==0?<BackgroundImage data={datosTickets} />:''}
       
        <main className={'l-main header-visible'} role="main">
        <ModalComplementa />

            <div className={' main'}>
            {/*<ModalLogin />*/}
           {/* <div className="timer-wrapper">
        <CountdownCircleTimer
          isPlaying
          duration={100}
          colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
          colorsTime={[10, 6, 3, 0]}
          onComplete={() => {console.log('completed')}}
        >
          {renderTime}
        </CountdownCircleTimer>
  </div>*/}
            <div className="paddingMain">  
        <section className={'block block-views block-views-cards-home-atracciones-block block-views-cards-home-atracciones-block'}>
       {datosTickets.length!==0? <CompraTicketsMobile data={datosTickets} /> :''}
            <div className={'view view-cards-home-atracciones view-id-cards_home_atracciones view-display-id-block view-dom-id-a1d681395865d9cc26a3b0abd78889c2'}>
                <HeaderCard />
                {datos.length!==0?<div className="">
                  <div className="d-none d-md-block"><ContentCard data={datos}/></div>
                  <div className="d-block d-md-none"> <CarouselCardAtracciones data={datos} /> </div>
                  </div>:''}
                  <div class="view-footer"> <a href={app.url+"/atracciones"} class="more-content btn-tex-mob-sb">Ver todos las atracciones <img width='28' height='16' src={app.url+"/sites/all/themes/tickets/2019/svg/flecha-derecha-azul.svg"}/></a> </div>
            </div>
            
        </section> 
        <Mapa />
        
        <section class="block block-views block-views-evento-home-2019-block block-views-evento-home-2019-block">
   <h2 class="block-title">Eventos</h2>
   <div class="view view-evento-home-2019 view-id-evento_home_2019 view-display-id-block view-dom-id-d1eb1bfdcbb295210c7992b81e1319cc">
      <div class="view-content slick-initialized slick-slider">
        
            
        <div style={{margin: '0 auto',
          color: '#333',
          display: 'block'}}>
        {datosEventos.length!==0?<CardEvento  data={datosEventos}/>:''}
        </div>
         
      </div>
      <div class="view-footer"> <a href="https://lasestacas.com/eventos" class="more-content btn-tex-mob-sb">Ver todos los eventos <img width='28' height='16' src={app.url+"/sites/all/themes/tickets/2019/svg/flecha-derecha-azul.svg"}/></a></div>
   </div>
   {datosInstagram.length!==0?<ContentInstagram data={datosInstagram}/>:''}

  </section>
  </div>
            
        </div>
       
       </main> 
        
        <PreFooter />
        <LazyLoadComponent>
        <footer className={'l-footer panel lazy'} role={'contentinfo'}>
            <div className={'footer'}>
            <NewsLetter />
            <MenuFooter data={datosMenuFooter}/>
            <Politicas />
            <Redes />
            </div>
            <Copyright />
        </footer>
        </LazyLoadComponent>
        <span id={'mostrarTooltip'} data-selector="tooltip-kxqp5vmd1" id="tooltip-kxqp5vmd1" class="tooltip tip-top custom-open"  role="tooltip" style={{visibility: 'visible', width: 'auto', inset: '610.594px auto auto 739.969px', }}>En caso de no acreditar la estatura, tendrás que pagar la diferencia en taquilla.<span class="nub"></span></span>
        </div>
        
  );
};

export default Header;