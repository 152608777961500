import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import React, { Component } from "react";
import Slider from "react-slick";
import $ from "jquery"
import { LazyLoadImage } from 'react-lazy-load-image-component';

const isBrowser = typeof window !== "undefined"


export default class CarouselCardAtracciones extends Component {
  render() {
     var datos=[]
      datos = this.props.data;
     
    const settings = {
      focusOnSelect: true,
      dots: false,
      infinite: true,
      centerPadding:'10px',
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      className: "slider variable-width",
    };

    const putData =(id,data)=>{
      //console.log('clic '+id);
      var modal = document.getElementById("myModal2");
      
           $('#menuNav').css('z-index','1')
       setTimeout(() => {
          modal.style.display = "block";
          $('#tituloModal').text(data.nombre);
          $('#srcModal').attr('src', data.card_image);
          $('#srcModal2').attr('src', data.card_image);

          $('#precioModal').text( data.precio);
          $('#precioModal2').text( data.precio);

          $('#letraChicaModal').text( data.desccard);
          $('#idModal').text( data.idproducto);

       }, 500);
  }
 
  setTimeout(() => {
  //console.log(id)
      var modal = document.getElementById("myModal2");
     /* $("#comprarComplementa"+id).unbind('click').click(function()
     {
       console.log('clic')
      $('#menuNav').css('z-index','1')
       
       modal.style.display = "block";

      /* if ($("#author_bio_wrap_toggle").text() == "Expand Author Details")
         {			
           $("#author_bio_wrap_toggle").html("Hide Author Details")
         }
       else 
         {		
           $("#author_bio_wrap_toggle").text("Expand Author Details")
         }
       
     });*/


     $("#close2").click(function()
     {
      modal.style.display = "none";

     });
     if (isBrowser) { 
     window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    }
 }
   }, 2000);
    return (
      <div>
         {console.log(datos)}
        <Slider {...settings}>
           { datos.map((data,index) => (
             <div className="widthCardEvento"  >
         {/* <Card id={(index+1)} data={datos} />*/}
         <div className={'block bloqueBlog mx-1 pb-5'} style={{flex:'unset', }}>
           <div>
                <LazyLoadImage className={'heightImagenCardBlog'} src={data.card_image} style={{borderRadius: '20px 20px 0px 0px',width:'100%'}} />
           </div>
           <div className={'my-4 mx-4 '} >
                <div>
               
                <div class="title-price views-fieldset" data-module="views_fieldsets">
                <div class="views-field views-field-view-node-1 d-flex justify-content-between">
                    <span class="field-content">
                    <p className="tituloInteres">{data.nombre=="La Estancia. Hotel para perros"?'La Estancia':data.nombre}</p> 
                    </span>
                    <div class="views-field views-field-commerce-price">
                    <span class="field-content title-price emphasis-v">{data.precio}</span>
                </div>
                </div>
                
            </div>


                </div>
                <div class="views-field views-field-field-desccardhome my-3 mb-5" >
                <div class="field-content">
                {  <div dangerouslySetInnerHTML={{__html: data.description}} /> }
                </div>
            </div>
               
           </div>
           
           {/*<div className={'text-center'} style={{marginTop: '23px'}}>
           <a href={datos[index].url}> <button class="secondary button radius form-submit mb-3 botonAdicional"  style={{marginTop:'0px',float:'unset'}} name="op" value="comprar" type="submit">Ver más info</button></a>
              <button class="ms-5 secondary button radius form-submit col-lg-5 mt-lg-0 mb-lg-3 col-md-2 mt-md-2 mb-md-3 col-4 mb-3 mt-2" onClick={()=>{putData(index,data)}}   id={"atracciones"+index} name="op" value="Buscar" style={{width:'124px'}} type="submit">Comprar</button>

                </div>*/}

<div className="row d-xl-none d-flex justify-content-center" style={{paddingLeft:'40px',paddingRight:'40px'}}>
            { /* <div dangerouslySetInnerHTML={{__html: datos[id-1].link_more_info}} />*/ }
            <div className="col-12 d-flex justify-content-center">
            <a href={data.url}> <button class="secondary button radius form-submit mb-3 botonAdicional"  style={{marginTop:'0px',float:'unset'}} name="op" value="comprar" type="submit">Ver más info</button></a>

            </div>
            <div className="col-12 d-flex justify-content-center">
            <button class=" secondary button radius form-submit col-lg-5 mt-lg-0 mb-lg-3 col-md-2 mt-md-2 mb-md-3 col-4 mb-3 mt-2" onClick={()=>{putData(data)}}   id={"atracciones"+data.id} name="op" value="Buscar" style={{width:'124px'}} type="submit">Comprar</button>

            </div>
            </div>
               </div>
       </div>
     
         
           ))
         }

          {/*<div>
          <img src={"https://s3.amazonaws.com/static.neostack.com/img/react-slick/abstract03.jpg"}style={{marginLeft:'auto',marginRight:'auto'}} />
          </div>
          <div>
          <img src={"https://s3.amazonaws.com/static.neostack.com/img/react-slick/abstract04.jpg"}style={{marginLeft:'auto',marginRight:'auto'}} />
          </div>
          <div>
          <img src={"https://s3.amazonaws.com/static.neostack.com/img/react-slick/abstract01.jpg"}style={{marginLeft:'auto',marginRight:'auto'}} />
          </div>
          <div>
          <img src={"https://s3.amazonaws.com/static.neostack.com/img/react-slick/abstract02.jpg"}style={{marginLeft:'auto',marginRight:'auto'}} />
          </div>*/}
        </Slider>
      </div>
    );
  }
}
